<template>
  <div class="aqz-container">
    <modalTerms v-model="modalTerms.open" :pageID="modalTerms.pageID" v-on:close="openModalTerms ({open: false, pageID: ''})"></modalTerms>
    <div class="customer-care-content after-care-sales">
      <div class="customer-care-content__header">
        <div class="content-title">
          <h1 class="title title-small">{{ $t('route.contact-after-sales')}}</h1>
          <h3 class="title subtitle">{{ $t('customerCare.contact.our-costumer-service') }} </h3>
        </div>
        <div class="working-hours">
          <b-row>
            <b-col md="8">
              <div class="working-hours__cta">
                <div class="working-hours__cta_single">
                  <a :href="'tel:' + $t('customerCare.contact.tel')" class="bcm-link bcm-link-primary">{{ $t('customerCare.contact.call') }}</a>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="customer-care-content__body">
        <div class="contact-form">
          <div class="form-intro">
            <span class="intro-title">{{ $t('customerCare.contact.form-intro-title')}}</span>
            <p class="intro-subtitle">{{ $t('customerCare.contact.form-intro-subtitle')}}</p>
          </div>
          <form @submit.prevent="handleSubmit" class="form-contact">
            <div class="mandatory-field">
              <span class="t-small">*{{ $t('mandatory-field') }}</span>
            </div>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group id="groupFirstname">
                  <base-input
                    name="firstname"
                    v-validate="'required'"
                    v-model="form.firstname"
                    type="text"
                    :placeholder="'* ' +$t('address.FirstName')"
                    :validations="[
                      {
                        condition: errors.has('firstname'),
                        text: errors.first('firstname')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group id="groupLastName">
                  <base-input
                    name="lastname"
                    v-validate="'required'"
                    v-model="form.lastname"
                    type="text"
                    :placeholder="'* ' +$t('address.LastName')"
                    :validations="[
                      {
                        condition: errors.has('lastname'),
                        text: errors.first('lastname')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group id="groupCountry">
                  <BaseSelect
                  name="country"
                  v-model="form.country"
                  v-validate="'required'"
                  :placeholder="'* ' +$t('address.Country')"
                  :options="optionsCountries"
                  :reduce="opt => opt.value"
                  :validations="[
                    {
                      condition: errors.has('country'),
                      text: errors.first('country')
                    }
                  ]"
                  >
                  </BaseSelect>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group id="groupCity">
                  <base-input
                    name="city"
                    v-validate="'required'"
                    v-model="form.city"
                    type="text"
                    :placeholder="'* ' +$t('address.City')"
                    :validations="[
                      {
                        condition: errors.has('city'),
                        text: errors.first('city')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group id="groupEmail">
                  <base-input
                    name="email"
                    v-validate="'required|email'"
                    v-model="form.email"
                    type="email"
                    :placeholder="'* ' + $t('address.Email')"
                    :validations="[
                      {
                        condition: errors.has('email'),
                        text: errors.first('email')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
               <b-col cols="12" md="6">
                <b-form-group id="groupStore">
                  <base-input
                    name="store"
                    v-validate="'required'"
                    v-model="form.store"
                    type="text"
                    :placeholder="'* ' +$t('customerCare.contact.store_location')"
                    :validations="[
                      {
                        condition: errors.has('store'),
                        text: errors.first('store')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
             <b-row>
             <b-col cols="12">
                <b-form-group id="groupOrderNumber">
                  <base-input
                    name="ordernumber"
                    v-validate="orderRequired"
                    v-model="form.ordernumber"
                    type="text"
                    :placeholder="(orderRequired ? '* ' : '') + $t('customerCare.contact.order_number')"
                    :validations="[
                      {
                        condition: errors.has('ordernumber'),
                        text: errors.first('ordernumber')
                      }
                    ]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group id="groupContactReason">
                  <BaseSelect
                    v-model="form.reason"
                    name="contactreason"
                    v-validate="'required'"
                    :placeholder="'* ' + $t('customerCare.contact.contact_reason')"
                    :options="optionsReason"
                    :validations="[
                      {
                        condition: errors.has('contactreason'),
                        text: errors.first('contactreason')
                      }
                    ]"
                  >
                  </BaseSelect>
                </b-form-group>
              </b-col>
               <b-col cols="12" md="6">
                 <b-form-group id="groupSize">
                  <base-input
                    name="store"
                    v-validate="'required'"
                    v-model="form.size"
                    type="text"
                    :placeholder="'* ' + $t('customerCare.contact.size')"
                    :validations="[
                      {
                        condition: errors.has('size'),
                        text: errors.first('size')
                      }
                    ]"
                  />
                </b-form-group>
             </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="groupPersonalMessage">
                  <BaseTextarea
                    name="personalmessage"
                    v-model="form.message"
                    v-validate="'required'"
                    :placeholder="'* ' + $t('customerCare.contact.write_message')"
                    :rows="12"
                    :number="true"
                    :validations="[
                      {
                        condition: errors.has('personalmessage'),
                        text: errors.first('personalmessage')
                      }
                    ]"
                  ></BaseTextarea>
                  <p class="cl-gray mt-2 mb-0">{{ totalcount }} {{ $t('customerCare.contact.charachters_left') }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="upload-file-row">
              <b-col md="4">
                <b-form-group id="groupUploadFile" class="upload-file-label">
                  <label class="custom-file-upload">
                    <input type="file" class="d-none" @change="processFile($event)" :accept="acceptFile"/>
                    <span class="upload-intro f-semibold">
                      <i class="aqz-icon aqz-icon-18-plus"></i>
                      {{$t('customerCare.contact.upload_file')}}
                    </span>
                  </label>
                  <ul class="files-list-text list-group">
                    <li v-for="(file, index) in form.uploadfile" :key="file.lastModified" class="filename-list list-group-item">
                      <button @click="removeFile(index)" class="btn btn-none font-weight-bold"><i class="ff-icon-04-close"></i></button>
                      {{file.name}}
                    </li>
                  </ul>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <div class="form-end-row">
                <b-col md="10">
                  <div class="form-end-intro">
                    <p class="text">
                      {{$t('customerCare.contact.privacy-info-transmitted')}}
                    </p>
                  </div>
                  <b-form-group id="groupCheckBox" class="privacy-check">
                    <base-check-box
                      name="privacy"
                      v-validate="'required:true'"
                      v-model="form.privacyChecked"
                      @label-clicked="openModalTerms({ open: true, pageID: 'privacy-policy' })"
                      :label="labelLinkPrivacy"
                      :validations="[
                        {
                          condition: errors.has('privacy'),
                          text: errors.first('privacy')
                        }
                      ]"
                    >
                    </base-check-box>
                  </b-form-group>
                </b-col>
                <b-col>
                  <button type="submit" class="btn btn-primary btn-primary submit-form">{{ $t('send') }}</button>
                </b-col>
              </div>
            </b-row>
          </form>
        </div>
      </div><!-- end body -->
      <div class="customer-care-content__footer">
      </div>
    </div>
  </div>
</template>

<script>
import ModalTerms from '@/theme/components/Modal/Terms'
import BaseInput from '@/components/Form/BaseInput'
import BaseSelect from '@/theme/components/Form/BaseSelect'
import BaseTextarea from '@/components/Form/BaseTextarea'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import Config from '@/config'
import { scrollTo } from '@/helpers/utils'
import CustomerService from '@/services/CustomerService'
import Logger from '@/services/Logger'
import { toast } from '@/modules/notifications'
import { pathTranslate } from '@/helpers/routeHelpers'

export default {
  name: 'AfterCareSales',
  inject: ['$validator'],
  head () {
    return {
      title: this.$t('Contact')
    }
  },
  data () {
    return {
      BrowseText: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        country: '',
        city: '',
        reason: null,
        message: '',
        ordernumber: '',
        uploadfile: [],
        store: '',
        size: '',
        privacyChecked: false
      },
      acceptFile: 'image/*,.docx,.pdf,.txt',
      modalTerms: {
        open: false,
        pageID: ''
      }
    }
  },
  methods: {
    openChat () {
      window.lz_chat_open && window.lz_chat_open()
    },
    openModalTerms ({ open, pageID }) {
      this.modalTerms = {
        open,
        pageID
      }
    },
    clearForm () {
      this.form.firstname = this.form.lastname = this.form.email = this.form.country = this.form.message = this.form.ordernumber = this.store = this.size = ''
      this.form.privacyChecked = false
      this.form.reason = null
      this.form.uploadfile = []
      this.$validator.reset()
    },
    renderContent (params) {
      return `<div><strong>${this.$t('customerCare.contact.contact_reason')}:</strong> <span>${params.reason.label}</span></div>
        <div><strong>${this.$t('customerCare.contact.order_number')}:</strong> <span>${params.ordernumber}</span></div>
        <div><strong>${this.$t('address.FirstName')}:</strong> <span>${params.firstname}</span></div>
        <div><strong>${this.$t('address.LastName')}:</strong> <span>${params.lastname}</span></div>
        <div><strong>${this.$t('address.Email')}:</strong> <span>${params.email}</span></div>
        <div><strong>${this.$t('address.Country')}:</strong> <span>${params.country}</span></div>
        <div><strong>${this.$t('address.City')}:</strong> <span>${params.city}</span></div>
        <div><strong>${this.$t('customerCare.contact.size')}:</strong> <span>${params.size}</span></div>
        <div><strong>${this.$t('customerCare.contact.store_location')}:</strong> <span>${params.store}</span></div>
        <div><strong>${this.$t('helpProduct.Message')}:</strong> <span>${params.message}</span></div>`
    },
    handleSubmit (evt) {
      const _this = this
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          _this.$store.commit('ui/showLoader')
          return CustomerService.sendCustomerCare({
            params: {
              ..._this.form,
              email_to: _this.form.reason.to,
              email_cc: _this.form.reason.cc,
              contentHTML: _this.renderContent(_this.form)
            },
            storeViewCode: _this.$store.state.ui.storeViewCode
          })
            .then(res => {
              if (res.data && res.data.success) {
                toast.success(_this.$t('Request successful'))
                _this.clearForm()
              } else {
                Logger.error(res)
                res.data && res.data.error_messages && toast.error(_this.$t(res.data.error_messages))
              }
            })
            .catch(err => {
              Logger.error(err)
              const data = err.response.data
              if (data && data.message) {
                toast.error(_this.$t(data.message))
              } else {
                toast.error(_this.$t('GenericError'))
              }
            })
            .finally(() => {
              _this.$store.commit('ui/hideLoader')
            })
        } else {
          scrollTo({
            className: 'is-invalid'
          })
        }
      })
    },
    processFile (event) {
      if (event.target.files.length) {
        // this.form.uploadfile.push(event.target.files[0])
        this.form.uploadfile = [event.target.files[0]]
      }
    },
    removeFile (indexFile) {
      this.form.uploadfile.splice(indexFile, 1)
    }
  },
  computed: {
    totalcount () {
      let maxCount = 400
      maxCount = maxCount - this.form.message.length
      return maxCount
    },

    optionsReason () {
      const _this = this
      return Config.Theme.contactUs.reason.map(item => ({
        ...item,
        label: _this.$t(`customerCare.contact.reasons.${item.value}`)
      }))
    },
    optionsCountries () {
      return this.$store.state.checkout.countries.map(country => ({
        value: country.id,
        label: country.full_name_locale
      }))
    },
    orderRequired () {
      if (this.form.reason && this.form.reason.mandatory && this.form.reason.mandatory.includes('orderid')) {
        return 'required'
      }
      return ''
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('termsMarketing', { linkPrivacy })
    }
  },
  mounted () {
    this.$store.dispatch('checkout/loadCountries')
    this.clearForm()
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseTextarea,
    ModalTerms,
    BaseCheckBox
  }
}
</script>
